.onboarding-progress-bar-alt {
  .bar {
    margin-top: 3em;
    position: relative;

    &.stage-1 {
      &::before {
        width: 0%;
      }
    }
    &.stage-2 {
      &::before {
        width: 25%;
      }
    }
    &.stage-3 {
      &::before {
        width: 50%;
      }
    }
    &.stage-4 {
      &::before {
        width: 75%;
      }
    }
    &.stage-full {
      &::before {
        width: 100%;
      }
    }
    &::before,
    &::after {
      content: "";
      display: block;
      height: 10px;
      border-radius: 10px;
      top: -20px;
    }
    &::before {
      background-color: var(--clr-secondary-500);
      transition: all 500ms ease-out;
      position: absolute;
      z-index: 1;
    }
    &::after {
      width: 100%;
      background-color: var(--clr-secondary-50);
      position: absolute;
      // top: 0;
      z-index: 0;
    }
  }
  .onboarding-progress {
    display: flex;
    margin-top: 1em;
    li {
      flex-basis: calc(100% / 4);
      position: relative;
    }
  }
}
