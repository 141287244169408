:root {
  /* COLORS */
  --clr-primary-50: #cce6e6;
  --clr-primary-400: #2a9595;
  --clr-primary-500: #008080;
  --clr-secondary-50: #d6d3e2;
  --clr-secondary-400: #544986;
  --clr-secondary-500: #32256e;
  --clr-secondary-600: #2a1f5c;
  --clr-secondary-700: #23203c; //hsla(246, 31%, 18%, 1);
  --clr-accent-200: #f9fafa;
  --clr-accent-300: #f6f7f9;
  --clr-accent-500: #ebedef;
  --clr-accent-550: #DDE1E9;
  --clr-dark-50: #dadada;
  --clr-dark-70: #c9cfd4;
  --clr-dark-100: #c1c1c1;
  --clr-dark-200: #9ba2a7;
  --clr-dark-250: #79716b;
  --clr-dark-260: #656b76;
  --clr-dark-300: #50555e;
  --clr-dark-350: #495969;
  --clr-dark-400: #36454f;
  --clr-dark-450: #1a1c1f;
  --clr-dark-500: #0b0a09;
  --clr-dark-600: #14151a;
  --clr-dark-700: #131212;
}

.bg-dark-100 {
  background-color: var(--clr-dark-100);
}

.text-dark-100 {
  color: var(--clr-dark-100);
}
.bg-dark-50 {
  background-color: var(--clr-dark-50);
}

.text-dark-50 {
  color: var(--clr-dark-50);
}
.bg-dark-200 {
  background-color: var(--clr-dark-200);
}

.text-dark-200 {
  color: var(--clr-dark-200);
}

.bg-dark-300 {
  background-color: var(--clr-dark-300);
}

.text-dark-300 {
  color: var(--clr-dark-300);
}
.bg-dark-400 {
  background-color: var(--clr-dark-400);
}

.text-dark-400 {
  color: var(--clr-dark-400);
}

.bg-dark-450 {
  background-color: var(--clr-dark-450);
}

.text-dark-450 {
  color: var(--clr-dark-450);
}

.bg-primary-500 {
  background-color: var(--clr-primary-500);
}

.text-primary-500 {
  color: var(--clr-primary-500);
}

.bg-dark {
  background-color: var(--clr-dark-500);
}

.text-dark {
  color: var(--clr-dark-500);
}

.border-primary-500 {
  border-color: var(--clr-primary-500);
}
