:root {
  /* FONT WEIGHT */
  --fw-thin: 100;
  --fw-extralight: 200;
  --fw-light: 300;
  --fw-regular: 400;
  --fw-medium: 500;
  --fw-semibold: 600;
  --fw-bold: 700;
  --fw-extrabold: 800;
  --fw-black: 900;

  /* FONT FAMILY */
  --ff-headline: "Matter SQ Regular";
  --ff-primary: "Matter SQ Regular";
  --ff-secondary: "Matter SQ Regular";
  --ff-primary-inverse: "Open Sans", sans-serif;
  --ff-secondary-inverse: "Roboto", sans-serif;
  --ff-body: var(--ff-primary);

  /* LINE HEIGHT*/
  --lh-100: 40px;
  --lh-150: 50px;
  --lh-200: 60px;
  --lh-section-title: 65px;

  /* FONT SIZES */
  --fs-nav: 0.93rem; //14px;
  // --fs-headline-500: ;
  // --fs-nav: ;
  --fs-300: 1.5rem;
}


@import url(https://db.onlinewebfonts.com/c/8611cf274fd66935b8f771ac84029521?family=Matter+SQ+Regular);
// @font-face {
  // font-family: "Matter SQ Regular";
  // src: url("https://db.onlinewebfonts.com/t/8611cf274fd66935b8f771ac84029521.eot");
  // src: url("https://db.onlinewebfonts.com/t/8611cf274fd66935b8f771ac84029521.eot?#iefix")
  //     format("embedded-opentype"),
  //   url("https://db.onlinewebfonts.com/t/8611cf274fd66935b8f771ac84029521.woff2")
  //     format("woff2"),
  //   url("https://db.onlinewebfonts.com/t/8611cf274fd66935b8f771ac84029521.woff")
  //     format("woff"),
  //   url("https://db.onlinewebfonts.com/t/8611cf274fd66935b8f771ac84029521.ttf")
  //     format("truetype"),
  //   url("https://db.onlinewebfonts.com/t/8611cf274fd66935b8f771ac84029521.svg#Matter SQ Regular")
  //     format("svg");
// }


@font-face {
  font-family: "Matter SQ Regular";
  src: url("../../fonts/Matter-Bold.woff") format("woff");
  src: url("../../fonts/MatterSQ-Bold.woff") format("woff");
  font-weight: 600;
}

@font-face {
  font-family: "Matter SQ Regular";
  src: url("../../fonts/Matter-Medium.woff") format("woff");
  src: url("../../fonts/MatterSQ-Medium.woff") format("woff");
  font-weight: 500;
}

@font-face {
  font-family: "Matter SQ Regular";
  src: url("../../fonts/Matter-Regular.woff") format("woff");
  src: url("../../fonts/MatterSQ-Regular.woff") format("woff");
  font-weight: 400;
}

// <uniquifier>: Use a uniquifier for the class name
// <weight>: Use a value from 300 to 800

.ff-headline {
  font-family: var(--ff-headline);
}
.ff-secondary {
  font-family: var(--ff-secondary);
}
.ff-primary-inverse {
  font-family: var(--ff-primary-inverse);
}
.ff-secondary-inverse {
  font-family: var(--ff-secondary-inverse);
}
.ff-body {
  font-family: var(--ff-body);
}

.fs-nav {
  font-size: var(--fs-nav);
}

.fs-300 {
  font-size: var(--fs-300);
}

.fw-thin {
  font-weight: var(--fw-thin);
}

.fw-extralight {
  font-weight: var(--fw-extralight);
}

.fw-light {
  font-weight: var(--fw-light);
}

.fw-regular {
  font-weight: var(--fw-regular);
}

.fw-medium {
  font-weight: var(--fw-medium);
}

.fw-semibold {
  font-weight: var(--fw-semibold);
}

.fw-black {
  font-weight: var(--fw-black);
}
