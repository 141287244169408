@use "../_partials/mixins";

.plan-option-card {
  display: block;
  padding: 20px 10px;
  background-color: #36454f;

  .footer {
    display: block;
    padding-top: 20px;
    position: relative;
    &::before,
    &::after {
      content: "";
      display: block;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      background-color: white;
      position: absolute;
      top: calc(-2.3em / 2);
    }

    &::before {
      left: -33px;
    }

    &::after {
      right: -33px;
    }
  }
}

input[type="radio"][name="plan-option"] {
  position: absolute;
  visibility: hidden;
  &:checked {
    & + label {
      color: red;
      .plan-option-card {
        background-color: var(--clr-secondary-500);
      }
    }
  }
}

.update-plan-options {
  input[type="radio"][name="plan-option"] {
    position: absolute;
    visibility: hidden;
    &:checked {
      & + label {
        color: white;
        font-weight: 600;
        display: flex;
        align-items: center;
        gap: 1em;
        &::after{
          content: "";
          display: block;
          width: 15px;
          height: 15px;
          background-image: url(../../images/icons/Icon-tick-white.svg);
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
        }
        //  border-radius: 10px;
        //  padding: 1em;
        //  margin-bottom: 0;
      }
    }
  }
}

@include mixins.maxmq(426px) {
  .plan-option-card {
    overflow: hidden;
    .footer {
      &::before,
      &::after {
        height: 15px;
        width: 15px;
        top: calc(-2.3em / 2);
      }

      &::before {
        left: -25px;
        // background-color: red;
      }

      &::after {
        right: -25px;
        // background-color: black;
      }
    }
  }
}

.default-payment-card {
  .title {
    &::after {
      content: "Default";
      font-size: 0.8rem;
      font-weight: 600;
      color: #854d0e;
      background: #fef08a;
      border-radius: 100vmax;
      padding: 0.3em 0.7em;
      margin-left: 1em;
    }
  }
}
