.activities {
  li {
    margin-bottom: 10px;

    .dot {
      width: 10px;
      min-width: 10px;
      height: 10px;
      min-height: 10px;
      background-color: var(--clr-primary-500);
      border-radius: 50%;
      z-index: 1;
    }
    &.horizontal-liner {
      &:not(:last-child) {
        @media (min-width: 768px) {
          .dot-wrap {
            position: relative;
            &::after {
              content: "";
              display: block;
              position: absolute;
              height: 100%;
              width: 1px;
              background-color: #eceef4;
              left: 25px;
              transform: translateX(-50%);
              z-index: 0;
            }
          }
        }
      }
    }
  }
}

.clocked-in {
  border: 1px solid #05acfa;
  background-color: #a9e3fd;
}

.clocked-out {
  background-color: #d6d3e2;
  color: #4a557d;
}
.break {
  background-color: #fef6e3;
  .title {
    color: #23283b;
  }
  .body {
    color: #4a557d;
  }
}
