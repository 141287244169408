// @use "../_abstracts/colors";
// @use "../_abstracts/typography";
* {
  box-sizing: border-box;
}

html,
body {
  font-size: 16px;
  width: 100%;
  // min-height: 100%;
  margin: 0;
  padding: 0;
  // font-family: typography.$bodyFontFamily;
  font-family: var(--ff-body);
  //   font: 100% var(--ff-body);
  font-weight: 400;
  color: var(--clr-dark-500);
  *:focus {
    box-shadow: none;
    outline: 0;
  }
}

html {
  scroll-behavior: smooth;
}
